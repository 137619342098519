import React from "react";

// Import images directly
import image1 from "../images/01.jpg";
import image2 from "../images/02.jpg";
import image3 from "../images/03.jpg";
import image4 from "../images/04.jpg";
import image5 from "../images/05.jpg";
import image6 from "../images/06.jpg";
import image7 from "../images/07.jpg";

import Hero from "../images/caseimages/hero.jpg";

function Home() {
  const handleButtonClick = (link) => {
    console.log(`Navigating to: ${link}`);
    window.location.href = link; // Navigate to the specified link
  };

  return (
    <div className="flex flex-col items-center justify-center text-center px-4 sm:px-8 md:px-16 lg:px-20 xl:px-32 ">
      <div class="md:w-2/3 text-center  mt-16">
        <h1 class="text-1xl md:text-2xl lg:text-3xl text-gray leading-10">
          Lily Chobanyan - UX/UI Designer
        </h1>
        <p>Merging art and technology to craft impactful solutions</p>
        <h3 class="text-lg mt-10">
          I specialize in creating intuitive digital experiences that blend
          creativity with usability. Explore my portfolio to see how I bring
          together aesthetics and user-friendly design.
        </h3>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-16">
        {[
          {
            image: image7,
            title: "ConnectTo",
            link: "/connectto",
          },
          {
            image: image5,
            title: "MICMA",
            link: "/micma",
          },
          {
            image: image1,
            title: "TrackMyLab",
            link: "/trackmylab",
          },
          {
            image: image3,
            title: "FitFoodFinder",
            link: "/fitfoodfinder",
          },

          {
            image: image6,
            title: "pH POS",
            link: "/pos",
          },
          {
            image: image2,
            title: "ArtWiz",
            link: "/artwiz",
          },
        ].map((item, index) => (
          <div
            key={index}
            className="relative group flex flex-col justify-center mb-8" // Adjust the bottom margin as needed
          >
            <div className="flex items-center lg:items-start text-left mb-4">
              <img
                src={item.image}
                alt={`Image ${index + 1}`}
                className="w-full h-auto"
                style={{ maxWidth: "350px" }}
              />
            </div>
            <div className="text-center md:text-left">
              <h2 className="text-2xl mb-2">{item.title}</h2>
              <button
                onClick={() => handleButtonClick(item.link)}
                className="font-medium hover:bg-blue border-blue hover:text-white-color text-blue border-solid border text-left bg-blue-500 text-white px-4 py-2 hover:bg-blue-600 transition-colors duration-500"
              >
                Go to Details
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Home;
